import { ClassValue } from 'clsx';
import { ReactElement, ReactNode } from 'react';
import {
  TButtonRound,
  TConsultationStatus,
  TEscriptsStatus,
  TEscriptsTokenStatus,
  TFileTypes,
  TOrderStatus,
  TConsultationLog,
  TConsultationUserType,
  TLimits,
  TShippingOption,
  TSupplementStatus
} from './types';
import { IScriptProduct } from './models/script-product-tokens';
import { ISupplement, ISupplementOrders } from './models/supplement';
import { IPatientDetails, IPatientOrderDetails, IPatientResponse } from './models/patient';
import { IConsultation, IConsultationDocuments, IConsultationType } from './models/consultation';
import { IDoctorDetails, IDoctorListSchedules, IDoctorResponse } from './models/doctor';

import { ICouponDetails } from './models/coupon-model';

export interface IGuard {
  children: ReactElement;
}

export interface ILoginResponse {
  access_token: string;
  expiry: number;
  refresh_token: string;
}

export interface IDoctorApiResponse extends IApiResponse {
  data: IDoctorResponse[];
}

export interface IPatientConsultationDetails {
  end_at: Date;
  start_at: Date;
  consultation_type_id: string;
  created_at: Date;
  doctor_id: string;
  doctor: IDoctorResponse;
  enquiry: string;
  id: string;
  patient_id: string;
  schedule_id: string | null;
  status: TConsultationStatus;
}

export interface IPatientConsultation {
  previous: IPatientConsultationDetails | null;
  upcoming: IPatientConsultationDetails | null;
}

export interface IConsultationTypeApiResponse extends IApiResponse {
  data: IConsultationType[];
}

export interface IConsultationsResponse extends IApiResponse {
  data: IConsultation[];
}

export type IMenuTypes = IMenuLink | IMenuAtag | IMenuButton | IMenuActions;

export interface IMenuActions extends IMenu {
  actions: IMenuTypes[];
  hoverable?: boolean;
}

export const isMenuActions = (action: any): action is IMenuActions =>
  action.actions instanceof Array;

export interface IMenu {
  // to: To;
  label: string;
  icon?: ReactNode;
  itemRound?: TButtonRound;
  className?: ClassValue;
  divider?: boolean;
  setting?: boolean;
}

export interface IMenuLink extends IMenu {
  to: string;
  state?: { [x: string]: any };
  replace?: boolean;
}

export const isIMenuLink = (action: any): action is IMenuLink => typeof action.to === 'string';

export interface IMenuAtag extends IMenu {
  href: string;
  target?: string;
}

export const isIMenuAtag = (action: any): action is IMenuAtag => typeof action.href === 'string';

export interface IMenuButton extends IMenu {
  onClick: (event: any) => void;
  type?: 'button' | 'submit';
}

export const isIMenuButton = (action: any): action is IMenuButton =>
  typeof action.onClick === 'function';

export interface ISelectOptions {
  value: any;
  label: string;
  disabled?: boolean;
  others?: ReactNode | string;
}

export interface IResources {
  id: string;
  title: string;
  eventColor?: string;
}

export interface ITimeOptionType {
  value: string;
  label: string;
  disabled?: boolean;
}

export interface IUsersResponse {
  contact_no: string;
  created_at: Date | null;
  email: string;
  email_verified_at: Date | null;
  id: string;
  is_super_admin: boolean;
  first_name: string;
  last_name: string;
  full_name: string;
  user_type: IUserTypesResponse | null;
  user_type_id: string | null;
}

export interface IUserTypesResponse {
  id: string;
  name: string;
  status: string;
  no_of_users: string;
  patient_access: boolean;
  doctor_access: boolean;
  consultation_access: boolean;
  product_access: boolean;
  prescription_access: boolean;
  coupon_access: boolean;
  reset_consultation_access: boolean;
  generate_prescription_access: boolean;
  reschedule_access: boolean;
  did_not_attend_access: boolean;
  cancel_access: boolean;
  consultation_logs_access: boolean; // @TODO
  login_patient_access: boolean;
  login_doctor_access: boolean;
  is_deleted: boolean;
  update_wallet_access: boolean;
  dispensing_limit_access: boolean;
  dispensing_interval_access: boolean;
  created_at: Date | null;
}

export interface IProductType {
  id: String;
  name: string;
  icon: TLimits;
  dispensing_limit: number | string;
  svg: TLimits;
  unit: string;
}

export interface IProductBrand {
  id: String;
  name: string;
}

export interface IProductResponse {
  brand: string;
  created_at: Date;
  format: string;
  id: string;
  product_id: string;
  product_name: string;
  status: string;
  price: string;
  name: string;
  schedule: string;
  stock: number;
  category: string;
  product_type: IProductType;
  size: number;
  image_url: string | null;
  cost: string;
  quantity: string;
  weight: number;
  // estimate_delivery: string;
  estimate_dispatch: string;
  administration: string;
  strength: string;
}

export interface IProductOrder {
  administration: string;
  brand: string;
  category: string;
  cost: number;
  created_at: Date;
  format: string;
  id: number;
  name: string;
  price: number;
  product_id: string;
  quantity: string;
  route: string;
  schedule: string;
  sku: string;
  status: 'ACTIVE' | 'INACTIVE';
  stock: number;
  strength: string;
  tags: any;
  updated_at: Date;
}

export interface ITabList {
  id?: string;
  name: string;
  show: 'table' | 'details' | 'calendar';
  timezone?: string;
}

export interface IExtendedDocuments extends IConsultationDocuments {
  consultation: IConsultation;
  consultation_doctor: IDoctorResponse;
}

export interface IPatientApiResponse extends IApiResponse {
  data: IPatientResponse;
}

export interface IProducts {
  id: string;
  type: string;
  brand: string;
  product_name: string;
  stock: number;
  format: string;
  schedule: string;
  price: number;
  is_active: boolean;
  product_type: IProductType;
  status: string;
}

export interface IProductSelection {
  id?: string;
  product_id: string;
  no_of_units: string;
  repeats: string;
  dosage: string;
  frequency: string;
  route: string;
  product?: IProducts;
}

export interface IProductCustom {
  id?: string;
  product_name: string;
  product_details: string;
  product_type: string;
  no_of_units: string;
  repeats: string;
  dosage: string;
  brand_name: string;
  sku: string;
  type: string;
  format: string;
  frequency: string;
  route: string;
}

export interface IPrescriptionDetails {
  id: string;
  reference_id: number;
  patient_id: string;
  consultation_id: string;
  products: IProductSelection[];
  custom_products: IProductCustom[];
  consultation: IConsultationDetails;
  consultation_notes: string;
  treatment_notes: string;
  careteam_notes: string;
  with_escript: boolean;
}

export interface IConsultationDetails {
  id: string;
  doctor: IDoctorDetails;
  patient: IPatientDetails;
  start_at: Date;
  end_at: Date;
  consultation_type: IConsultationType;
  enquiry: string;
  // step: TBookingSteps;
  status: TConsultationStatus;
  prescription?: IPrescriptionDetails;
  tga_approval: boolean;
  tga_acceptance: boolean;
  script_approval: boolean;
  documents: IConsultationDocuments[];
}

export interface ICouponResponse {
  id: string;
  coupon_type: string;
  code: string;
  price_discount: number;
  limit: number;
  is_deleted: boolean;
  created_at: Date;
  usage: number;
  doctor_ids: string[];
  consultation_type_ids: string[];
}

export interface IEscripts {
  id: number;
  script_id: string;
  // patient: string;
  doctor: string;
  consultation_date: Date;
  product: IProductResponse;
  description: string;
  reference_id: number;
  remaining: number;
  total: number;
  status: TEscriptsStatus;
  tokens: IScriptToken[];
  expiry: Date;
  // item: IEscriptsItem;
}

export interface IEscriptsItem {
  administration: string | null;
  category: string;
  cost: number;
  created_at: Date;
  dispensed_at: Date | null;
  doctor_address: string;
  doctor_credentials: string;
  doctor_id: string;
  doctor_name: string;
  doctor_phone: string;
  doctor_signature: string;
  dose: string;
  due_at: Date | null;
  expiry: Date;
  format: string | null;
  frequency: string;
  id: number;
  invoiced_at: Date | null;
  order_id: string | null;
  paid_at: Date | null;
  patient_address: string;
  patient_dob: string;
  patient_email: string;
  patient_id: string;
  patient_name: string;
  patient_phone: string;
  prescriber_no: string;
  price: number;
  product_brand: string;
  product_id: string;
  product_name: string;
  quantity: string;
  route: string;
  schedule: string;
  script_id: string;
  sku: string;
  source: string;
  source_id: string;
  source_ref: string;
  source_time: string;
  status: TEscriptsStatus;
  strength: string | null;
  token_id: string;
  token_no: number;
  total_count: number;
  updated_at: Date;
}

export interface IScriptList {
  category: string;
  created_at: Date;
  doctor_address: string;
  doctor_credentials: string;
  doctor_id: number;
  doctor_name: string;
  doctor_phone: string;
  dose: string;
  expiry: Date;
  frequency: string;
  id: number;
  patient_address: string;
  patient_dob: string;
  patient_email: string;
  patient_id: string;
  patient_name: string;
  patient_phone: string;
  prescriber_no: string;
  price: number;
  product_brand: string;
  product_id: number;
  product_name: string;
  quantity: number;
  remaining: number;
  route: string;
  schedule: string;
  script_id: string;
  sku: string;
  source: string;
  source_id: string;
  source_ref: string | null; // remove null for production
  status: TEscriptsStatus;
  total_count: number;
  token_count: number;
  updated_at: Date;
  tokens: IScriptToken[];
}

export interface IScriptToken {
  token_id: string;
  token_no: number;
  source: string | null; // remove null for production
  source_id: string | null; // remove null for production
  source_ref: string | null; // remove null for production
  script_id: string;
  source_time: Date;
  patient_id: string;
  patient_name: string | null; // remove null for production
  patient_dob: string | null; // remove null for production
  patient_email: string | null; // remove null for production
  patient_phone: string | null; // remove null for production
  patient_address: string | null; // remove null for production
  doctor_id: number;
  doctor_name: string | null; // remove null for production
  doctor_credentials: string | null; // remove null for production
  doctor_phone: string | null; // remove null for production
  doctor_address: string | null; // remove null for production
  prescriber_no: string | null; // remove null for production
  product_id: number;
  product_brand: string | null; // remove null for production
  product_name: string | null; // remove null for production
  dose: string;
  frequency: string;
  route: string;
  quantity: string | null;
  schedule: string | null; // remove null for production
  category: string | null; // remove null for production
  sku: string | null; // remove null for production
  price: number | null;
  status: TEscriptsTokenStatus;
  expiry: Date;
  due_at: Date | null;
  invoiced_at: Date | null;
  paid_at: Date | null;
  pharmacist_id: string | null;
  pharmacist_name: string | null; // remove null for production
  pharmacist_phone: string | null; // remove null for production
  pharmacist_address: string | null; // remove null for production
  dispensed_at: Date | null;
  created_at: Date;
  updated_at: Date | null;
}

export interface IOrdersList {
  id: number;
  order_id: string;
  patient_id: string;
  patient_email: string | null;
  patient_name: string | null;
  patient_dob: string | null;
  patient_phone: string | null;
  patient_address: string | null;
  status: TOrderStatus;
  invoiced_at: Date | null;
  paid_at: Date | null;
  price: number | null;
  pharmacist_id: string | null;
  pharmacist_name: string | null;
  pharmacist_phone: string | null;
  pharmacist_address: string | null;
  dispensed_at: Date | null;
  created_at: Date;
  updated_at: Date;
  tokens: IScriptToken[];
  // for removal later
  email: string | null;
  password: string | null;
  salt: string | null;
  first_name: string | null;
  middle_names: string | null;
  last_name: string | null;
  dob: Date | null;
  phone: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  postcode: number | null;
  country: string | null;
  medicare_no: string | null;
  medicare_ref: number | null;
  medicare_expiry: Date | null;
  order_notes: string;
}

export interface IOrder {
  category: string;
  created_at: Date;
  doctor_address: string;
  doctor_credentials: string;
  doctor_id: number;
  doctor_name: string;
  doctor_phone: string;
  dose: string;
  expiry: Date;
  frequency: string;
  id: number;
  patient_address: string;
  patient_dob: string;
  patient_email: string;
  patient_id: string;
  patient_name: string;
  patient_phone: string;
  prescriber_no: string;
  price: number;
  product_brand: string;
  product_id: number;
  product_name: string;
  quantity: number;
  remaining: number;
  route: string;
  schedule: string;
  script_id: string;
  sku: string;
  source: string;
  source_id: string;
  source_ref: string;
  status: TEscriptsStatus;
  total_count: number;
  token_count: number;
  updated_at: Date;
  tokens: IScriptToken[];
}

export interface IFileMutation {
  id?: number;
  type?: string;
  filename: string;
  file_url: string;
  filetype?: TFileTypes;
}

export interface IFileCheckResponse {
  success: boolean;
  message: string;
}

export interface IOrderHistoryResponse extends IApiResponse {
  data: IOrderHistory[];
}

export interface IOrderHistory {
  id: string;
  order_no: number;
  price: number;
  shipping_option: TShippingOption;
  shipping_fee: number;
  status: TOrderStatus;
  created_at: Date;
  products: { product: IScriptProduct[]; quantity: number };
  supplement: { supplement: ISupplement[]; quantity: number };
}

export interface IConsultationTimeline {
  log: TConsultationLog;
  created_at: Date;
  name: string;
  log_type: any;
  usertype: TConsultationUserType;
  consultations: IConsultation;
}

export interface ITransactions {
  created_at: Date;
  updated_at: Date;
  status: number;
  id: number;
  reference_id: string;
  uuid: string;
  disbursement_bank: string | null;
  processing_bank: string | null;
  external_reference: string | null;
  reference_amount: string | null;
  internal_state: string | null;
  internal_status: string | null;
  account_external: {
    account_type_id: number;
    currency: {
      code: string;
    };
  };
  external_account_details: string;
  external_account_details_encrypted: boolean;
  marketplace: {
    group_name: string | null;
    name: string;
    short_name: string | null;
    uuid: string;
    account_details: string;
    account_details_encrypted: boolean;
  };
  first_name: string;
  last_name: string;
  user_email: string;
  user_external_id: string;
  payout_currency: string | null;
  type: string;
  type_method: string;
  batch_id: number;
  cuscal_payment_transaction_id: string | null;
  reference: string;
  state: string;
  user_id: string;
  account_id: string;
  from_user_name: string;
  from_user_id: string;
  refund_state: string | null;
  account_type: string;
  amount: number;
  currency: string;
  country: string | null;
  debit_credit: string;
  item: {
    id: string | null;
    item_number: string | null;
  };
  description: string;
  related: {
    account_to: {
      id: string;
      account_type: string;
      user_id: string;
    };
  };
}

export interface IDispensingLimit {
  dispensing_limit: number;
  icon: TLimits;
  id: string;
  name: string;
  unit: string;
  remaining: number;
}

export interface IMedicineDetails {
  type: string;
  frequency: string;
}

export interface ICurrentMedication {
  name: string;
  dose: string;
  frequency: string;
  reason: string;
}

export interface IPatientReferrals {
  created_at?: string;
  id: string;
  other_timeframe?: string;
  other_type?: string;
  script_id: string;
  status: string;
  timeframe: string;
  type: string;
  updated_at?: string;
}
export interface IConsultationPrescription extends IConsultation {
  id: string;
  patient_id: string;
  consultation_id: string;
  submitted_at: Date;
  created_at: Date;
  updated_at: Date;
  followup_period: string | null;
  followup_value: number;
  set_followup: boolean;
  dispensing_interval: number;
  consultation_notes: string;
  careteam_notes: string;
  prescriptions: IEscriptPrescription[];
  supplements: IEscriptSupplement[];
  lifestyles: IEscriptLifestyle[];
  clinical_notes: IClinicalNote[];
  referrals: IPatientReferrals[];
  patient_notes: string | null;
  // prescription: {
  //   id: string;
  //   patient_id: string;
  //   consultation_id: string;
  //   consultation_notes: string;
  //   treatment_notes: string;
  //   careteam_notes: string;
  //   with_escript: boolean;
  //   submitted_at: Date;
  //   created_at: Date;
  //   updated_at: Date;
  //   dispensing_notes: string;
  //   followup_period: string;
  //   followup_value: number;
  //   set_followup: boolean;
  //   dispensing_interval: boolean;
  //   prescription_products: IPrescriptionProducts[];
  // };
}

export interface IPrescriptionProducts {
  id: string;
  prescription_id: string;
  product_id: string;
  no_of_units: string;
  repeats: string;
  dosage: string;
  frequency: string;
  route: string;
  created_at: Date;
  updated_at: Date;
  product: IProductsConsultations;
}

export interface IProductsConsultations {
  id: string;
  product_type_id: string;
  brand: string;
  product_name: string;
  format: string;
  schedule: string;
  price: string;
  stock: number;
  type: string;
  quantity: number;
}

export interface IApiResponse {
  status: number;
  count: number;
  message: string | null;
}

export interface IEscriptApiResponse {
  status: number;
  success: boolean;
  data?: any;
}

export interface IEscriptPrescriptionResponse extends IApiResponse {
  data: IEscriptPrescriptionData[];
}

export interface IEscriptPrescriptionData {
  id: string;
  patient_name: string;
  date_of_birth: string;
  active_products: number;
  active_tokens: number;
  summary: IEscriptPrescriptionSummary[];
}

export interface IEscriptPrescriptionSummary {
  product_id: string;
  product: IEscriptPrescriptionProduct;
}

export interface IEscriptPrescriptionProduct {
  id: number;
  product_id: string;
  sku: string;
  brand: string;
  name: string;
  product_type_id: string;
  format: string;
  administration: string;
  route: string;
  strength: string;
  quantity: string;
  schedule: string;
  category: string;
  size: number;
  size_unit: string;
  cost: number;
  price: number;
  image_url: string | null;
  stock: number;
  status: string;
  estimate_dispatch: Date | null;
  weight: number;
  created_at: Date;
  updated_at: Date;
  product_type: IProductType;
  tags: IEscriptPrescriptionProductTag[];
  tokens: {
    id: string;
    status: TEscriptsTokenStatus;
    consultation_no: string;
    doctor: string;
    consultation_date: string;
    expiry: Date;
  }[];
}

export interface IEscriptPrescriptionProductTag {
  id: number;
  name: string;
  icon: string;
  description: string;
  color: string;
  visible: boolean;
  product_id: string;
  order: number;
  created_at: Date;
  updated_at: Date;
}

export interface IEscriptOrdersResponse extends IApiResponse {
  data: IEscriptOrdersData[];
}

export interface IEscriptOrdersData {
  id: string;
  order_no: number;
  patient_id: string;
  patient_name: string;
  date_of_birth: string;
  patient_address: string;
  patient_city: string;
  patient_postcode: string;
  patient_state: string;
  price: number;
  is_paid: boolean;
  notes: string;
  products: IEscriptOrdersProducts[];
  ordered_at: Date;
  status: TOrderStatus;
  is_first_order: boolean;
  order_address: string;
}

export interface IEscriptOrdersProducts {
  order_id: string;
  product_id: string;
  product_brand: string;
  product_name: string;
  dose: string;
  frequency: string;
  route: string;
  quantity: string;
  format: string | null;
  administration: string | null;
  strength: string | null;
  category: string;
  schedule: string;
  price: number;
  status: string;
  availability: boolean;
  doctor_id: string;
  doctor_name: string;
  consultation_time: Date;
}

export interface IEscriptDispenseResponse extends IApiResponse {
  data: IEscriptDispenseData[];
}

export interface IEscriptDispenseData {
  id: string;
  order_no: number;
  patient_name: string;
  date_of_birth: string;
  patient_address: string;
  order_address: string;
  products: IEscriptDispenseProducts[];
  notes: string | null;
  status: TOrderStatus;
  is_first_order: boolean;
  with_shipit: boolean;
}

export interface IEscriptDispenseProducts {
  order_id: string;
  token_id: number;
  product_id: string;
  product_brand: string;
  product_name: string;
  product_type: IDispensingLimit;
  dose: string;
  frequency: string;
  route: string;
  quantity: string;
  format: string | null;
  administration: string | null;
  strength: string | null;
  category: string;
  schedule: string;
  price: number;
  status: TEscriptsTokenStatus;
  availability: boolean;
  doctor_name: string;
  prescribed_at: Date;
  qrcode: string;
  type: string;
}

export interface IEscriptScriptProductsResponse extends IEscriptApiResponse {
  data: IEscriptScriptProducts;
}

export interface IEscriptScriptProducts {
  id: string;
  product_id: string;
  administration: string;
  dosage: string;
  frequency: string;
  expiry: Date;
  price: number;
  route: string;
  total_count: number;
  product: IEscriptProductsData;
  quantity: number;
  script_product_tokens: [];
}

export interface IEscriptProductsResponse extends IApiResponse {
  data: IEscriptProductsData[];
}

export interface IEscriptProductsData {
  id: string;
  sku: string;
  name: string;
  image_url: string | null;
  stock: number;
  price: number;
  days_to_expire: number;
  product_type_id: string;
  product_brand_id: string;
  brand: string;
  product_details: IEscriptProductsDetailsData;
  product_type: IEscriptProductsTypeData;
  product_brand: IEscriptProductsBrandData;
  status:
    | 'ACTIVE'
    | 'IN_STOCK'
    | 'STOCK_LOW'
    | 'STOCK_ORDERED'
    | 'STOCK_IN_TRANSIT'
    | 'OUT_OF_STOCK'
    | 'INACTIVE'
    | 'DISCONTINUED';
  created_at: Date;
  updated_at: Date;
  is_formulary?: string;
}

export interface IEscriptSupplementsResponse extends IApiResponse {
  data: IEscriptSupplementsData[];
}

export interface IEscriptSupplementsData {
  id: string;
  sku: string;
  name: string;
  image_url: string;
  stock: number;
  stock_last_updated: Date;
  price: number;
  status:
    | 'ACTIVE'
    // | 'IN_STOCK'
    // | 'STOCK_LOW'
    // | 'STOCK_ORDERED'
    // | 'STOCK_IN_TRANSIT'
    // | 'OUT_OF_STOCK'
    | 'INACTIVE'
    | 'DISCONTINUED';
  created_at: Date;
  updated_at: Date;
}

export interface IEscriptProductsDetailsData {
  format: string;
  strength: string;
  schedule: string;
  category: string;
  administration: string;
  route: string;
  size: number;
  weight: number;
  estimate_dispatch: string | null;
  stock_estimate_time_arrival: string | null;
}

export interface IEscriptProductsTypeData {
  id: string;
  name: string;
  icon: TLimits;
  unit: string;
  dispensing_limit: number;
  order: number;
}

export interface IEscriptProductsBrandData {
  id: string;
  name: string;
}

export interface IDispensingLimitHistoryResponse extends IApiResponse {
  data: IDispensingLimitHistory[];
}
export interface IDispensingLimitHistory {
  clinical_notes: string;
  created_at: Date;
  doctor: { fullname: string };
  new_dispensing_limit: number;
  old_dispensing_limit: number;
  product_type: IDispensingLimit;
  updated_by: string;
  updated_at: string;
}

export interface IEscriptOrdersHistoryResponse extends IApiResponse {
  data: IDispensingLimitHistory[];
}

export interface IEscriptOrderHistoryData {
  id: string;
  order_no: number;
  patient_name: string;
  date_of_birth: string;
  patient_address: string;
  order_address: string;
  price: number;
  is_paid: boolean;
  pharmacist_name: string | null;
  pharmacist_phone: string | null;
  pharmacist_address: string | null;
  dispensed_at: Date;
  notes: string | null;
  products: IEscriptOrderHistoryProducts[];
}

export interface IEscriptOrderHistoryProducts {
  order_id: string;
  product_id: string;
  product_brand: string;
  product_name: string;
  dose: string;
  frequency: string;
  route: string;
  quantity: string;
  format: string | null;
  administration: string | null;
  strength: string | null;
  category: string;
  schedule: string;
  price: number;
  status: TEscriptsTokenStatus;
}

export interface IEscriptOpenOrderResponse extends IApiResponse {
  data: IEscriptOpenOrderData[];
}

export interface IEscriptOpenOrderData {
  id: string;
  reference_id: number;
  consultation_date: Date;
  product: {
    id: string;
    brand: string;
    product_name: string;
    format: string;
    schedule: string;
    price: number;
    is_active: boolean;
    stock_last_updated: Date;
  };
  description: string;
  remaining: number;
  expiry: Date;
  tokens: IEscriptOpenOrderTokens[];
  total: number;
  status: TEscriptsStatus;
  product_image: string | null;
  availability: boolean;
  dispatch_days: string | null;
  reach_limit: boolean;
  monthly_order: number;
  product_size: number;
  dispensing_limits: IDispensingLimit[];
  last_order: Date;
  order_available_in: string | null;
  price: number;
}

export interface IEscriptOrderDataResponse extends IApiResponse {
  data: IOrderDetailsData | null;
}

export interface IOrderDetailsData {
  id: string;
  patient_id: string;
  order_no: number;
  order_notes: string | null;
  shipping_option: TShippingOption;
  shipping_fee: number;
  paid_amount: number;
  status: TEscriptsStatus;
  paid_at: Date | null;
  shipit_number: string | null;
  created_at: Date;
  updated_at: Date;
  supplement_orders: ISupplementOrders[];
  // script_product_tokens: IScriptProductTokens[];
  product_orders: IScriptProduct[];
  patient: IPatientOrderDetails;
  delivery_address: string;
  delivery_city: string;
  delivery_postcode: string;
  delivery_state: string;
}

export interface IOrderDetailsTokens {
  id: string;
  product_id: string;
  script_product_id: string;
  product_brand: string;
  product_name: string;
  route: string;
  dose: string;
  frequency: string;
  product: IEscriptProductsData;
  quantity: string;
  format: string;
  administration: string;
  strength: string;
  category: string;
  schedule: string;
  sku: string;
  cost: number;
  price: number;
  total_count: number;
  script_id: string;
  is_refunded: boolean;
  refund_reason: string | null;
  expiry: Date;
  script_product_tokens: [];
}
export interface IEscriptOpenOrderTokens {
  id: number;
  token_id: string;
  token_no: number;
  total_count: number;
  script_id: string;
  product_id: string;
  product_brand: string;
  product_name: string;
  dose: string;
  frequency: string;
  route: string;
  quantity: string;
  format: string | null;
  administration: string | null;
  strength: string | null;
  category: string;
  schedule: string;
  sku: string;
  cost: number;
  price: number;
  status: TEscriptsTokenStatus;
  expiry: Date;
  created_at: Date;
  updated_at: Date;
}

export interface IEscriptCartResponse extends IApiResponse {
  cart: IEscriptCartData[];
  price: number;
}

export interface IEscriptCartData {
  id: string;
  escript: {
    id: string;
    product: {
      id: string;
      brand: string;
      product_name: string;
      format: string;
      schedule: string;
      price: number;
      is_active: boolean;
      product_type_id: IDispensingLimit;
    };
    description: string;
    remaining: number;
    total: number;
    status: TEscriptsStatus;
    product_image: string;
    availability: boolean;
    dispatch_days: string;
    reach_limit: boolean;
    monthly_order: number;
    product_size: number;
    dispensing_limits: number;
    last_order: Date;
    order_available_in: Date;
  };
  quantity: number;
}

export interface IPaymentCardToken {
  token: string;
  user_id: string;
}

export interface IEscriptZaiToken extends IApiResponse {
  data: IPaymentCardToken;
}

export interface IPaymentProp {
  paymentId: string;
  isExist: boolean;
}

export interface IEscriptCheckoutProp {
  patient_id: string;
  processPayment: boolean;
  source_id: string;
  items: ICheckoutItem[];
}

export interface ICheckoutItem {
  script_product_id: string;
  quantity: number;
}

export interface IAmendOrderData {
  escript_id: string;
  quantity: number;
  product_name: string;
  product_brand: string;
  format: string | null;
  price: number;
  limit: number;
}

export interface IEScriptAmendOrderItem {
  escript_id: string;
  quantity: number;
}

export interface IEscriptAmendOrderProp {
  order_id: string;
  processPayment: boolean;
  source_id: string;
  items: IEScriptAmendOrderItem[];
}

export interface IRefundTokenResponse extends IEscriptsItem {
  is_refunded: boolean;
  refund_reason: string;
  refund_by: string;
  refund_at: Date;
  product: IEscriptProductsData;
}

export interface IPrintOrder extends IApiResponse {
  data: IEscriptPrintOrderData[];
}

export interface IEscriptPrintOrderData {
  label_base64_string: string;
  label_type: string;
}

export interface IEscriptPrescription {
  prescription_id: string;
  product_id: string;
  product: IProducts;
  dosage: string;
  frequency: string;
  no_of_units: string;
  repeats: string;
  route: string;
  remaining: number;
  doctor: IDoctorDetails;
  tokens: IScriptToken[];
  description: string;
  total: number;
  expiry: string;
  consultation_no: string;
  consultation_date: string;
  prescribed_at: string;
}

export interface IEscriptSupplement {
  dosage: string;
  prescription_id: string;
  supplement_id: string;
  supplement: ISupplement;
  remaining: number;
}

export interface IEscriptLifestyle {
  advice: string;
  duration: string;
  frequency: string;
}

export interface IClinicalNote {
  created_at?: string;
  id: string;
  note: string;
  note_type: string;
  script_id: string;
  status: string;
  updated_at?: string;
}

export interface IPatientEscriptsResponse extends IEscriptApiResponse {
  prescriptions: IEscriptPrescription[];
  supplements: IEscriptSupplement[];
  lifestyles: IEscriptLifestyle[];
}

export interface ICartProduct {
  product: IProducts;
  quantity: number;
}

export interface ICartSupplement {
  supplement: ISupplement;
  quantity: number;
}

export interface IZaiTokenResponse extends IApiResponse {
  data: IPaymentCardToken;
}

export interface IDoctorListSchedulesResponse extends IApiResponse {
  data: IDoctorListSchedules[];
}

// start
export interface IEscriptSingleOrderHistoryResponse extends IApiResponse {
  data: IOrderHistory[];
}

export interface IEscriptSingleOrderHistory {
  order_id: number;
  total: number;
  admin_id: string;
  log: string;
  created_at: Date;
  order: IOrderDetailsData | null;
  products: IEscriptProductsData[];
  shipping_fee: number | null;
  processed_by: string | null;
  coupon: ICouponDetails | null;
}

export interface IPharmacy {
  id: string;
  name: string;
  created_at: Date;
  updated_at: Date;
}

export interface IOrderHistory {
  log_time: string;
  processed_by: string;
  product: string;
  transaction_status: string;
}

export interface IOrderHeaderData {
  orders: number;
  review: number;
  dispensing: number;
  processed: number | null;
  shipping: number;
}

export interface IOrderHeaderDataEntries {
  name: string;
  count: number | null;
}

export interface IEscriptOrdersEScriptType extends IEscriptOrdersProducts {
  script_type: IEscriptType;
}

export interface IEscriptType {
  initial: string;
  name: string;
}

export interface IEscriptReviewOrdersData {
  id: string;
  order_no: number;
  patient_id: string;
  patient_name: string;
  date_of_birth: string;
  patient_address: string;
  patient_city: string;
  patient_postcode: string;
  patient_state: string;
  price: number;
  is_paid: boolean;
  notes: string | null;
  products: IEscriptOrdersEScriptType[];
  ordered_at: Date | null;
  order_date: Date | null;
  delivery_city: string;
  delivery_postal: string;
  delivery_state: string;
  delivery_street: string;
  status: TOrderStatus;
}

export interface IPharmacy {
  id: string;
  name: string;
  created_at: Date;
  updated_at: Date;
}

export interface IPatientCartPrescription {
  brand: string;
  category: string;
  cbd: string;
  cultivar: string;
  description: string;
  format: string;
  id: string;
  image_url: string;
  price: number;
  product_brand: {
    id: string;
    name: string;
  };
  product_image: string;
  product_name: string;
  schedule: string;
  size: string;
  size_unit: string;
  stock: number;
  strength: string;
  type: string;
  weight: string;
}

export interface IPatientCartSupplement {
  days_to_expire: number;
  id: string;
  image_url: string;
  name: string;
  price: number;
  sku: string;
  status: TSupplementStatus;
  stock: number;
  stock_last_updated: Date;
}

export interface IPatientCartItem {
  prescription: ICartProduct | null;
  supplement: ICartSupplement | null;
  quantity: number;
}

export interface IPatientCart {
  cart: IPatientCartItem[];
}